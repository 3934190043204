import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-img-dialog',
  template: `
    <mat-dialog-content>
      <img [src]="imgUrl">
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./img-dialog.component.css']
})
export class ImgDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public imgUrl: string) {
  }

  ngOnInit() {
  }

}
