// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  azureAD: {
    tenantId: 'd9e4a585-9556-49fc-9313-57eec813073a',
    applicationId: '43c8a10e-f90a-4402-a337-54154c1a3f2e',
    postLogoutRedirectUri: 'https://service.bdcgtoronto.ca/internal'
  },
  apiBase: "https://bdcg-production.appspot.com/",
  firebase: {
    apiKey: "AIzaSyBXMsL0beEMXvX1zMUDzQjUV5nmDOokX4w",
    authDomain: "bdcg-production.firebaseapp.com",
    databaseURL: "https://bdcg-production.firebaseio.com",
    projectId: "bdcg-production",
    storageBucket: "bdcg-production.appspot.com",
    messagingSenderId: "647482797821",
    appId: "1:647482797821:web:1fca034f79b13af4fb1894"
  }
};
/*
export const environment = {
  production: true,
  azureAD: {
    tenantId: 'd9e4a585-9556-49fc-9313-57eec813073a',
    applicationId: '43c8a10e-f90a-4402-a337-54154c1a3f2e',
    postLogoutRedirectUri: 'https://bangladesh-214214.firebaseapp.com/'
  },
  apiBase: "https://bdcg-production.appspot.com/",
  firebase: {
    apiKey: "AIzaSyAljVMN8UzeUdQtnJERIfq3ll7_Joniz7c",
    authDomain: "service.bdcgtoronto.ca",
    databaseURL: "https://bdcg-production.firebaseio.com",
    projectId: "bdcg-production",
    storageBucket: "",
    messagingSenderId: "647482797821"
  }
};
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
