import { Injectable } from "@angular/core";
import { ApiHttpService } from "./api-url.service";
import saveAs from "file-saver";

@Injectable({
	providedIn: 'root'
})
export class LoanApplicationService {
	url: string = "";
	constructor(private apiHttpService: ApiHttpService) { }

	//Admin Service::BEGIN
	getApplication(id: any) {
		this.url = "loan/loanApplication/get-application-detail-admin?id=" + id;
		return this.apiHttpService.GET(this.url);
	}

	getApplicationChecklist(id: any) {
		this.url = "loan/loanApplication/get-application-checklist?id=" + id;
		return this.apiHttpService.GET(this.url);
	}

	getLoanApplicationPageDetails(id: any) {
		this.url = "loan/loanApplication/get-application-detail-tab?id=" + id;
		return this.apiHttpService.GET(this.url);
	}

	createService(fileName: any, data: any) {
		this.url = "loan/loanApplication/create-application";
		return this.apiHttpService.POST(this.url, data);
	}
	downloadPdfFileWithLink(fileName: any) {        
        this.url = 'loan/loanApplication/get-pdf-document-generated/' + fileName;
        this.apiHttpService.DownloadFile(this.url).subscribe(data => {			
            var blob = new Blob([data], { type: data.type });
            saveAs(blob, fileName);			
        })
    }
	updateService(fileName: any, data: any) {
		this.url = "loan/loanApplication/update-application";
		return this.apiHttpService.PUT(this.url, data);
	}

	updateServicePayment(fileName: any, data: any) {
		this.url = "loan/loanApplication/update-application-payment";
		return this.apiHttpService.PUT(this.url, data);
	}

	getServiceAmount(fileName: any, data: any) {
		this.url = "loan/loanApplication/get-service-price";
		return this.apiHttpService.POST(this.url, data);
	}
	//get-service-servicetype-validity
	getDeliveryTypeValidity(fileName: any, data: any) {
	this.url = "loan/loanApplication/get-service-servicetype-validity";
	return this.apiHttpService.POST(this.url, data);
	}
	updateChecklist(data: any[]) {
		this.url = "loan/loanApplication/update-checklist";
		return this.apiHttpService.PUT(this.url, data);
	}

	uploadDocumentList(id: any, data: any[]) {
		this.url = "loan/loanApplication/upload-application-documents/" + id;
		return this.apiHttpService.PUT(this.url, data);
	}

	getApplicationDocumentList(id: any) {
		this.url = "loan/loanApplication/get-application-documents?id=" + id;
		return this.apiHttpService.GET(this.url);
	}

	getApplicationLoanDocumentCategory(id: any) {
		this.url =
			"loan/loanApplication/get-application-document-category?id=" + id;
		return this.apiHttpService.GET(this.url);
	}

	downloadLatestFile(id: any, fileName: any) {
		this.url = "loan/loanApplication/download-lastest-file/" + id;
		this.apiHttpService.DownloadFile(this.url).subscribe(data => {
			var blob = new Blob([data], { type: data.type });
			saveAs(blob, fileName);
		});
	}

	getNoteMessage(id: any) {
        this.url = 'loan/loanApplication/get-application-qa-notelist/' + id;
        return this.apiHttpService.GET(this.url);
    }

	createNoteMessage(applicationId: any, messageNote: any) {
		const formData = new FormData();
			formData.append("applicationId", applicationId);
			formData.append("messageNote", messageNote.toString());
        this.url = 'loan/loanApplication/create-client-note-message';
        return this.apiHttpService.POST(this.url, formData);
    }

	deleteNoteRecord(id: any) {
		this.url = "loan/loanApplication/delete-note/" + id;
		return this.apiHttpService.DELETE(this.url);
	}

	deleteDocumentRecord(id: any) {
		this.url = "loan/loanApplication/delete-document/" + id;
		return this.apiHttpService.DELETE(this.url);
	}

	deleteApplication(id: any) {
		this.url = "loan/loanApplication/delete-application/" + id;
		return this.apiHttpService.DELETE(this.url);
	}

	getSecondaryClientDataSource(parentContactId: any) {
        this.url = 'loan/loanApplication/get-secondary-client-source/' + parentContactId;
        return this.apiHttpService.GET(this.url);
    }
	//Admin Service::END
}
