import { Component, OnInit } from '@angular/core';

import { InternalUserService } from './core/internal-user/internal-user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private internalUserService: InternalUserService) {
  }

  ngOnInit() {
    this.internalUserService.handleLoginCallback();
  }

}
