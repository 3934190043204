import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { throwError, Observable, empty } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MessageService } from './message.service';
import { ExternalUserService } from '../external-user/external-user.service';

@Injectable()
export class MessageInterceptor implements HttpInterceptor {

  constructor(private message: MessageService,
              private router: Router,
              private externalUserService: ExternalUserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
               .pipe(catchError((err, _) => {

                 if (err.status === 0) {
                   this.message.error("The server is unavailable right now. Please try again shortly.").subscribe();
                   return throwError(err);
                 }

                 if (err.error && err.error.error_code == 'EMAIL_NOT_VERIFIED') {
                   this.router.navigate(['email-verification']);
                   return empty();
                 }

                 if (err.error && err.error.error_code == 'EXTERNAL_ACCOUNT_DISABLED') {
                   this.message.error("Your account is disabled.").subscribe(() => {
                     this.externalUserService.logout().subscribe(() => {
                      this.router.navigate(['/sign-in']);
                     });
                   });
                   return empty();
                 }

                 if (err.error && err.error.error_code == 'EXTERNAL_TOKEN_WITHOUT_EMAIL_ADDRESS') {
                  this.message.error(
                      "We were unable to obtain an email address from you Facebook account.<br>" +
                      "In order to register with our service, we require an email address.<br>" +
                      "Please try another means of registration.").subscribe(() => {
                    this.externalUserService.logout().subscribe(() => {
                     this.router.navigate(['/sign-in']);
                    });
                  });
                  return empty();
                }

                 if (err.status === 403) {
                   this.message.error("You are not authorized to perform this operation.").subscribe();
                   return throwError(err);
                 }

                 if (err.status && err.error.errorMessage === 404) {
                  this.message.error(err.error.errorMessage).subscribe();
                   return throwError(err);
                 }

                 if (err.error && err.error.errorMessage && err.status === 401) {
                  this.message.error(err.error.errorMessage).subscribe();
                  return throwError(err);
                }

                 if (err.error && err.error.error_messages) {
                   this.message.error(err.error.error_messages.join('<br>')).subscribe();
                   return throwError(err);
                 }

                 if (err.error && err.error.errorMessage) {
                  this.message.error(err.error.errorMessage).subscribe();
                  return throwError(err);
                }

                 if (err.error) {
                   this.message.error(err.error).subscribe();
                   return throwError(err);
                 }

                 this.message.error(err.message).subscribe();
                 return throwError(err);
               }));
  }

}
