import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class FileDownloaderService {

  constructor(private domSanitizer: DomSanitizer) { }

  downloadFromResponse(content: HttpResponse<ArrayBuffer>) {
    const header = content.headers.get('Content-Disposition');
    const filename = new RegExp('filename=(.*)').exec(header)[1];
    this.download(content.body, 'application/octet-stream', filename);
  }

  download(content: ArrayBuffer, contentType: string, filename: string) {
    // https://medium.com/@radicalloop/download-file-using-ajax-in-angular-4-50109564bf17
    var blob = new Blob([content], { type: contentType });
    var link = document.createElement('a');
    var url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  createURL(content: ArrayBuffer) {
    var blob = new Blob([content], { type: 'application/octet-stream' });
    return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
  }

}
