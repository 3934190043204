import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ExternalUserService } from './external-user.service';

@Injectable()
export class ExternalUserInterceptor implements HttpInterceptor {

  constructor(private externalUserService: ExternalUserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(`${environment.apiBase}external`)) {
      return this.externalUserService.acquireAuthToken().pipe(concatMap(token => {
        var headersMod = req.headers.set('Authorization', `Bearer ${token}`);
        // Website you wish to allow to connect
        headersMod.set('Access-Control-Allow-Origin', 'http://localhost:4200');

        // Request methods you wish to allow
        headersMod.set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');

        // Request headers you wish to allow
        headersMod.set('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

        // Set to true if you need the website to include cookies in the requests sent
        // to the API (e.g. in case you use sessions)
        headersMod.set('Access-Control-Allow-Credentials', 'true');
        return next.handle(req.clone({
          withCredentials: true,
          headers: headersMod
        }));
      }));
    } else {
      return next.handle(req);
    }
  }

}
