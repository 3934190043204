import { Injectable, Inject } from "@angular/core";
import {
	HttpClient,
	HttpHeaders,
	HttpErrorResponse,
	HttpParams
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from '../environments/environment';
import { Options } from "selenium-webdriver";
import { Http, Response, Headers, RequestOptions } from "@angular/http"

@Injectable()
export class ApiHttpService {
	private apiUrl: string = "";
	private rootUrl: string = "";

	constructor(private http: HttpClient) {
		this.apiUrl = environment.apiUrl;
		this.rootUrl = environment.rootUrl;
	}
	getHttpHeaders() {
		let header = new HttpHeaders()
			.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))
			.set('responseType', 'json');
		let headers = { headers: header };
		return headers;
	}
	getHttpHeadersBLOB() {
		let header = new HttpHeaders()
			.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))			
		let headers = { headers: header };
		return headers;
	}
	getApiUrl(url: string): string {
		return this.apiUrl + url;
	}

	getRootUrl(url: string): string {
		return this.rootUrl + url;
	}

	GET(url: string): Observable<any> {
		return this.http.get(this.apiUrl + url, this.getHttpHeaders());
	}

	POST(url: string, data: any): Observable<any> {
		return this.http.post(this.apiUrl + url, data, this.getHttpHeaders());
	}

	GetToken(url: string, data: any): Observable<any> {
		return this.http.post(this.rootUrl + url, data, this.getHttpHeaders());
	}

	PUT(url: string, data: any): Observable<any> {
		return this.http.put(this.apiUrl + url, data, this.getHttpHeaders());
	}

	DELETE(url: string): Observable<any> {
		return this.http.delete(this.apiUrl + url, this.getHttpHeaders());
	}

	DownloadFile(url: string): Observable<any> {			
		let headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
        return this.http.get(this.apiUrl + url, {headers: headers, responseType: 'blob' });		
    }
}
