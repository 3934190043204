import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { FormsModule } from '@angular/forms';

import { AlertDialogComponent } from './alert-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { ImgDialogComponent } from './img-dialog.component';
import { InputDialogComponent } from './input-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [
    AlertDialogComponent,
    ConfirmationDialogComponent,
    ImgDialogComponent,
    InputDialogComponent
  ],
  entryComponents: [
    AlertDialogComponent,
    ConfirmationDialogComponent,
    ImgDialogComponent,
    InputDialogComponent
  ]
})
export class DialogsModule { }
