import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MatDialog } from '@angular/material';
import { SafeResourceUrl } from "@angular/platform-browser";

import { AlertDialogComponent } from '../../dialogs/alert-dialog.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog.component';
import { ImgDialogComponent } from "../../dialogs/img-dialog.component";
import { InputDialogComponent } from "../../dialogs/input-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private dialog: MatDialog) {
  }

  info(subject: string): Observable<never> {
    return new Observable(subscriber => {

      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: subject
      });

      dialogRef.afterClosed().subscribe(result => {
        subscriber.next();
        subscriber.complete();
      });

    });
  }

  input(subject: string): Observable<string> {
    return new Observable(subscriber => {

      const dialogRef = this.dialog.open(InputDialogComponent, {
        data: subject
      });

      dialogRef.afterClosed().subscribe(result => {
        subscriber.next(result);
        subscriber.complete();
      });

    });
  }

  error(subject: string): Observable<never> {
    return new Observable(subscriber => {

      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: subject
      });

      dialogRef.afterClosed().subscribe(result => {
        subscriber.next();
        subscriber.complete();
      });

    });
  }

  confirm(subject?: string, dangerous: boolean = false): Observable<boolean> {
    return new Observable(subscriber => {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          subject: subject || 'Are you sure?',
          dangerous
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        subscriber.next(result);
        subscriber.complete();
      });

    });
  }

  img(url: string | SafeResourceUrl): Observable<never> {
    return new Observable(subscriber => {

      const dialogRef = this.dialog.open(ImgDialogComponent, {
        data: url
      });

      dialogRef.afterClosed().subscribe(result => {
        subscriber.next();
        subscriber.complete();
      });

    });
  }

}
