import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class TokenStorageService {
	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
    public getAccessToken(): Observable<string> {
        const token: string = <string>localStorage.getItem('accessToken');
        return of(token);
    }

	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
    public getRefreshToken(): Observable<string> {
        const token: string = <string>localStorage.getItem('refreshToken');
        return of(token);
    }

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
    public getUserRoles(): Observable<any> {
        const roles: any = localStorage.getItem('userRoles');
        try {
            return of(JSON.parse(roles));
        } catch (e) { }
    }

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
    public setAccessToken(token: string): TokenStorageService {
        localStorage.setItem('accessToken', token);

        return this;
    }

	/**
	 * Set refresh token
	 * @returns {TokenStorage}
	 */
    public setRefreshToken(token: string): TokenStorageService {
        localStorage.setItem('refreshToken', token);

        return this;
    }

	/**
	 * Set user roles
	 * @param roles
	 * @returns {TokenStorage}
	 */
    public setUserRoles(roles: any): any {
        if (roles != null) {
            localStorage.setItem('userRoles', JSON.stringify(roles));
        }

        return this;
    }

	/**
	 * Remove tokens
	 */
    public clear() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userRoles');
        localStorage.removeItem('usr');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userContactId');
        localStorage.removeItem('currentUserId');
        localStorage.removeItem('currentUserEmail');
        localStorage.removeItem('requireVerification');
        localStorage.removeItem('publicHoliDays');
        localStorage.removeItem('appointmentSubmitMode');
        localStorage.removeItem('currentApplication');
        localStorage.removeItem('applicationId');
        localStorage.removeItem('appointmentDeliveryMode');
        localStorage.removeItem('hasVerified');
        localStorage.removeItem('userContactId');
        localStorage.removeItem('requesterEmail');

        indexedDB.deleteDatabase('firebaseLocalStorageDb');
    }
}
