import { Injectable } from "@angular/core";
import { ApiHttpService } from "./api-url.service";
import saveAs from "file-saver";

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    url: string = "";
    constructor(private apiHttpService: ApiHttpService) { }

    updateContact(data: any) {
        this.url = "crm/contact/update-bdcg-contact";
        return this.apiHttpService.POST(this.url, data);
    }

    updateServiceContact(data: any) {
        this.url = "crm/contact/update-service-contact";
        return this.apiHttpService.POST(this.url, data);
    }
}
