import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  template: `
    <mat-dialog-content [innerHTML]="data.subject"></mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-raised-button color="{{data.dangerous ? 'warn' : 'primary'}}" [mat-dialog-close]="true">Yes</button>
      <button mat-raised-button [mat-dialog-close]="false">No</button>
    </mat-dialog-actions>
  `
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {subject: string, dangerous: boolean}) {
  }

  ngOnInit() {
  }

}
