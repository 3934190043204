import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AzureADAuthService } from './azure-ad-auth.service';

@Injectable()
export class AzureADAuthInterceptor implements HttpInterceptor {

  constructor(private azureADAuthService: AzureADAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(`${environment.apiBase}internal`)) {
      return this.azureADAuthService.acquireAuthToken().pipe(concatMap(token => {
        return next.handle(req.clone({
          withCredentials: true,
          headers: req.headers.set('Authorization', `Bearer ${token}`)
        }));
      }));
    } else {
      return next.handle(req);
    }
  }

}
