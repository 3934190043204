import { Injectable, ElementRef, NgZone, Inject, InjectionToken } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Page } from '../page/page';
import { FileDownloaderService } from '../file-downloader/file-downloader.service';
import { ExternalUser, ExternalUserCitizenInfo } from './external-user';
import { Router } from '@angular/router';

export const FIREBASE_DI_TOKEN = new InjectionToken<any>('firebase');
export const FIREBASE_UI_DI_TOKEN = new InjectionToken<any>('firebaseui');

@Injectable({
  providedIn: 'root'
})
export class ExternalUserService {

  constructor(private http: HttpClient,
    private zone: NgZone,
    private router: Router,
    private fileDownloader: FileDownloaderService,
    @Inject(FIREBASE_DI_TOKEN) private firebase: any,
    @Inject(FIREBASE_UI_DI_TOKEN) private firebaseui: any) {
    // Initialize Firebase
    var config = environment.firebase;
    firebase.initializeApp(config);
  }

  isLoggedIn() {
    return this.firebase.auth().currentUser != null;
  }

  acquireAuthToken(): Observable<string> {
    if (!this.firebase.auth().currentUser) {
      return of(null);
    }
    return from(this.firebase.auth().currentUser.getIdToken());
  }

  triggerEmailVerification(): Observable<never> {
    return new Observable(subscriber => {
      if (this.firebase.auth().currentUser) {
        const user = this.firebase.auth().currentUser;
        user.sendEmailVerification()
          .then(() => subscriber.next())
          .catch((err: any) => subscriber.error(err));
      } else {
        this.firebase.auth().onAuthStateChanged((user: any) => {
          this.zone.run(() => {
            if (user) {
              user.sendEmailVerification()
                .then(() => subscriber.next())
                .catch((err: any) => subscriber.error(err));
            } else {
              subscriber.error(null);
            }
          });
        });
      }
    });
  }

  finishVerification(actionCode: any) {
    return new Observable(subscriber => {
      this.firebase.auth().applyActionCode(actionCode)
        .then(() => {
          this.firebase.auth().signOut()
            .then(() => subscriber.next())
            .catch((err: any) => subscriber.error(err));
        })
        .catch((err: any) => subscriber.error(err));
    });
  }

  validateResetPasswordToken(actionCode: any) {
    return new Observable(subscriber => {
      this.firebase.auth().verifyPasswordResetCode(actionCode)
        .then(() => {
          this.firebase.auth().signOut()
            .then(() => subscriber.next())
            .catch((err: any) => subscriber.error(err));
        })
        .catch((err: any) => subscriber.error(err));
    });
  }

  changePassword(actionCode: any, newPassword: string) {
    return new Observable(subscriber => {
      this.firebase.auth().confirmPasswordReset(actionCode, newPassword)
        .then(() => {
          this.firebase.auth().signOut()
            .then(() => subscriber.next())
            .catch((err: any) => subscriber.error(err));
        })
        .catch((err: any) => subscriber.error(err));
    });
  }

  getUserDetails(): Observable<ExternalUser> {
    return new Observable(subscriber => {
      if (this.firebase.auth().currentUser) {
        const user = this.firebase.auth().currentUser;
        subscriber.next(this.mapToExternalUser(user));
      } else {
        this.firebase.auth().onAuthStateChanged(user => {
          this.zone.run(() => {
            if (user) {
              if(user.emailVerified) {
                subscriber.next(this.mapToExternalUser(user));
              }
              else {
                this.router.navigate(['/email-verification']);
              }
            } else {
              subscriber.next(null);
            }
          });
        });
      }
    });
  }

  setupLoginComponent(wrapperEl: ElementRef) {

    // FirebaseUI config.
    var uiConfig = {
      signInSuccessUrl: '/',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        this.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        this.firebase.auth.EmailAuthProvider.PROVIDER_ID,
        //this.firebase.auth.FacebookAuthProvider.PROVIDER_ID
      ]
    };

    // Initialize the FirebaseUI Widget using Firebase.
    var ui = this.firebaseui.auth.AuthUI.getInstance() || new this.firebaseui.auth.AuthUI(this.firebase.auth());//new this.firebaseui.auth.AuthUI(this.firebase.auth());
    // The start method will wait until the DOM is loaded.
    ui.start(wrapperEl.nativeElement, uiConfig);

  }

  logout(): Observable<never> {
    return new Observable(subscriber => {
      this.firebase.auth().signOut().then(() => {
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  getCitizenInfo(extra: boolean = false): Observable<ExternalUserCitizenInfo> {
    let params = new HttpParams();
    if (extra) {
      params = params.append('extra', 'true');
    }
    const basicInfo = <Observable<ExternalUserCitizenInfo>>this.http
      .get(`${environment.apiBase}external/user`, {
        params
      });
    if (!this.firebase.auth().currentUser) {
      return this.getUserDetails().pipe(concatMap(() => basicInfo));
    } else {
      return basicInfo;
    }
  }

  getCitizenInfoInternal(id: string): Observable<ExternalUserCitizenInfo> {
    return <Observable<ExternalUserCitizenInfo>>this.http
      .get(`${environment.apiBase}internal/external-users/${id}`);
  }

  updateCitizenInfoInternal(id: string,
    phoneNumber: string, phoneCountryCode: string,
    smsConfirmationCode: string, firstName: string,
    lastName: string, canadianStatus: string, bangladeshCitizen: boolean,
    extra?: any): Observable<never> {
    return <Observable<never>>
      this.http.put(`${environment.apiBase}internal/external-users/${id}`, {
        cell_phone_number: phoneNumber,
        cell_phone_country_code: phoneCountryCode,
        first_name: firstName,
        last_name: lastName,
        sms_confirmation_code: smsConfirmationCode,
        canadian_status: canadianStatus,
        bangladesh_citizen: bangladeshCitizen,
        extra: extra
      });
  }

  exportCSV() {
    this.http.get(`${environment.apiBase}internal/external-users?format=csv`,
      { responseType: "arraybuffer" })
      .subscribe(resp => {
        this.fileDownloader.download(resp, "text/csv; charset=utf-8", "Citizen DB.csv")
      });
  }

  fetchAll(query?: string, deleted: boolean = false, page: number = 1): Observable<Page<ExternalUserCitizenInfo>> {
    let params = new HttpParams().append("page", page.toString());
    if (query) {
      params = params.append("query", query);
    }
    const url = deleted ?
      `${environment.apiBase}internal/admin/external-users/deleted` :
      `${environment.apiBase}internal/external-users`;
    return <Observable<Page<ExternalUserCitizenInfo>>>this.http.get(url, { params });
  }

  disable(userId: string): Observable<never> {
    return <Observable<never>>this.http
      .delete(`${environment.apiBase}internal/external-users/${userId}`);
  }

  undelete(userId: string): Observable<never> {
    return <Observable<never>>this.http
      .post(`${environment.apiBase}internal/admin/external-users/${userId}/undelete`, {});
  }

  private mapToExternalUser(firebaseUser): ExternalUser {
    return {
      name: firebaseUser.displayName,
      email: firebaseUser.email,
      photoURL: firebaseUser.photoURL,
      userreferenceid: firebaseUser.uid,
      hasPrivacyPolicyAccepted: false
    };
  }
}
