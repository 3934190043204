import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoginDataService {

    private loginSource = new BehaviorSubject('');
    currentLogin = this.loginSource.asObservable();

    constructor() { }

    changeLoginState(userName: string) {
        this.loginSource.next(userName)
    }
}