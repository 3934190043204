import { Injectable } from "@angular/core";
import { ApiHttpService } from "./api-url.service";
import saveAs from "file-saver";
import { CreateAppointmentInputModel } from "../application-edit/application-details.component";
import { AppointmentRescheduleModel } from "../appointment/manage-appointment.component";

@Injectable({
	providedIn: 'root'
})

export class ManageAppointmentService {
	url: string = "";
  constructor(private apiHttpService: ApiHttpService) { }

  //#region Manage Appointment::BEGIN

  createAppointment(appointment: CreateAppointmentInputModel) {
		this.url = "service/serviceAppointment/save-service-appointment";
		return this.apiHttpService.POST(this.url, appointment);
	}

  rescheduleAppointment(appointment: AppointmentRescheduleModel) {
		this.url = "service/serviceAppointment/update-appointment-date-counter";
		return this.apiHttpService.POST(this.url, appointment);
	}

  removeAppointment(appointmentId: number) {
		this.url = "service/serviceAppointment/remove-appointment/" + appointmentId;
		return this.apiHttpService.GET(this.url);
	}

  getAvailableTimeSlots(date, serviceTypeId, appointmentTypeId) {
		this.url = "service/serviceAppointment/get-appointment-available-time-slot/" + serviceTypeId + "/" + appointmentTypeId;
		return this.apiHttpService.POST(this.url, date);
  }

  getServiceAppointmentAvailableTimeSlots(date, serviceTypeId, appointmentTypeId) {
    const dateStr = (date.getMonth() + 1) + ' ' + date.getDate() + ' ' + date.getFullYear();
		this.url = "service/serviceAppointment/get-service-appointment-available-time-slot-v2/" + serviceTypeId + "/" + appointmentTypeId + "/false/" + dateStr;
		return this.apiHttpService.POST(this.url, null);
  }

  GetServiceAppointmentTimeSlotAvailability(appointment: CreateAppointmentInputModel) {
		this.url = "service/serviceAppointment/get-service-appointment-time-slot-availability";
		return this.apiHttpService.POST(this.url, appointment);
  }

  getPublicHolidays(fromDate) {
    const dateStr = (fromDate.getMonth()+1) + '' + fromDate.getDate() + '' + fromDate.getFullYear();
		this.url = "service/serviceAppointment/get-public-holidays-v2/" + dateStr;
		return this.apiHttpService.POST(this.url, null);
	}

  getServiceTypes() {
		this.url = "loan/configurations/get-service-type-list";
		return this.apiHttpService.GET(this.url);
  }

  getAppointmentCandidateServices(customerId) {
		this.url = "loan/loanApplication/get-appointment-candidate-service-list-by-customer/" + customerId;
		return this.apiHttpService.GET(this.url);
  }

  getCurrentServiceAppointmentsAsync(contactId) {
		this.url = "service/serviceAppointment/get-current-service-appointments/" + contactId;
		return this.apiHttpService.GET(this.url);
  }

  getServiceAppointmentsHistoryAsync(customerId) {
		this.url = "service/serviceAppointment/get-service-appointments-history/" + customerId;
		return this.apiHttpService.GET(this.url);
	}

  //#endregion Manage Appointment::END

  //#region Sample::BEGIN

	getApplication(id: any) {
		this.url = "loan/loanApplication/get-application-detail-admin?id=" + id;
		return this.apiHttpService.GET(this.url);
	}



	updateService(fileName: any, data: any) {
		this.url = "loan/loanApplication/update-application";
		return this.apiHttpService.PUT(this.url, data);
	}

	updateServicePayment(fileName: any, data: any) {
		this.url = "loan/loanApplication/update-application-payment";
		return this.apiHttpService.PUT(this.url, data);
	}

	deleteApplication(id: any) {
		this.url = "loan/loanApplication/delete-application/" + id;
		return this.apiHttpService.DELETE(this.url);
  }

  //#endregion Sample::END
}
