import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-alert-dialog',
  template: `
    <mat-dialog-content [innerHTML]="subject"></mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Ok</button>
    </mat-dialog-actions>
  `
})
export class AlertDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public subject: string) {
  }

  ngOnInit() {
  }

}
