import { EventEmitter, InjectionToken, Component } from "@angular/core";


export const ACTIONS_LIST_DI_TOKEN = new InjectionToken<Action[]>('actions');
export const FAB_ACTION_DI_TOKEN = new InjectionToken<FabAction>('fab-action');


export interface Action {
  title: string;
  icon?: string;
  color?: string;
  routerCommands?: any[];
  click: EventEmitter<never>;
}


export interface FabAction {
  title?: string;
  icon?: string;
  routerCommands?: any[];
  click?: EventEmitter<never>;
}


export interface DrawerAction {
  title: string;
  icon?: string;
  routerCommands: any[];
}
