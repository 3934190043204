import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'internal',
    loadChildren: '../app/internal/internal.module#InternalModule'
  },
  {
    path: 'external',
    loadChildren: '../app/external/external.module#ExternalModule'
  },
  {
    path: '',
    loadChildren: '../app/service/service.module#ServiceModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), FormsModule, ReactiveFormsModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
