import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-input-dialog',
  template: `
    <mat-dialog-content>
      <mat-form-field class="large-single-field">
        <input type="text" matInput [placeholder]="subject"
               [(ngModel)]="content">
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-raised-button color="primary" [mat-dialog-close]="content">Confirm</button>
      <button mat-raised-button [mat-dialog-close]="">Cancel</button>
    </mat-dialog-actions>
  `
})
export class InputDialogComponent implements OnInit {

  content: string;

  constructor(@Inject(MAT_DIALOG_DATA) public subject: string) {
  }

  ngOnInit() {
  }

}
