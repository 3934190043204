import { Injectable } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";

import { environment } from '../../../environments/environment';
import { AzureADAuthService } from "../azure-ad-auth/azure-ad-auth.service";
import { MessageService } from "../message/message.service";
import { InternalUser, InternalUserInfo } from "./internal-user";
import { Page } from "../page/page";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class InternalUserService {

  constructor(private azureAdAuthService: AzureADAuthService,
              private messageService: MessageService,
              private http: HttpClient) {
  }

  getUserDetails(): Observable<InternalUser> {
    const cachedUser = this.azureAdAuthService.getCachedUser();
    if (!cachedUser) {
      return of(null);
    }
    return this.http
      .get(`${environment.apiBase}internal/user/admin`)
      .pipe(map(admin => <InternalUser>{
          name: cachedUser.profile.name,
          email: cachedUser.profile.email,
          given_name: cachedUser.profile.given_name,
          admin
      }));
  }

  logout() {
    const confirmationMessage =
      'This will log you out of all Microsoft applications. ' +
      'Do you want to proceed?';
    this.messageService.confirm(confirmationMessage).subscribe(confirmed => {
      if (confirmed) {
        this.azureAdAuthService.logout();
      }
    });
  }

  handleLoginCallback() {
    this.azureAdAuthService.handleLoginCallback();
  }

  fetchAll(query?: string, page: number = 1): Observable<Page<InternalUserInfo>> {
    let  params = new HttpParams().append("page", page.toString());
    if (query) {
      params = params.append("query", query);
    }
    return <Observable<Page<InternalUserInfo>>>this.http.get(
      `${environment.apiBase}internal/admin/staff`,
      { params }
    );
  }

  fetchDetails(id: string): Observable<InternalUserInfo> {
    return <Observable<InternalUserInfo>>this.http
      .get(`${environment.apiBase}internal/admin/staff/${id}`);
  }

  create(data: any): Observable<never> {
    return <Observable<never>>this.http
      .post(`${environment.apiBase}internal/admin/staff`, data);
  }

  update(id: string, data: any): Observable<never> {
    return <Observable<never>>this.http
      .put(`${environment.apiBase}internal/admin/staff/${id}`, data);
  }

}
