import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { AppComponent } from './app.component';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoanApplicationService } from './service/rest-service/loan-application.service';
import { ApiHttpService } from './service/rest-service/api-url.service';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from './service/rest-service/token-storage-service';
import { LoginDataService } from './service/shared-service/login-data-service';
import { ContactService } from './service/rest-service/contact-service';
import { ImageCropperModule } from 'ngx-image-cropper';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { DxValidationGroupModule, DxValidatorModule, DxValidationSummaryModule } from 'devextreme-angular';
import { ManageAppointmentService } from './service/rest-service/manage-appointment.service';
import { DatePipe } from '@angular/common';
import { LoginComponent } from './authentication/login/login.component';
import { RegisterComponent } from './authentication/register';
import { AlertComponent } from './authentication/util-components';
import { ErrorInterceptor, fakeBackendProvider, JwtInterceptor } from './authentication/auth-helpers';
// import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
// import {
//   GoogleLoginProvider
// } from 'angularx-social-login';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    DialogsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule.forRoot(),
    ImageCropperModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    DeviceDetectorModule.forRoot(),
    //SocialLoginModule
  ],
  providers: [
    LoanApplicationService,
    ManageAppointmentService,
    ApiHttpService,
    TokenStorageService,
    LoginDataService,
    ContactService,
    DatePipe,
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: "",
    //         //provider: ""//new GoogleLoginProvider('849271891971-nkvqhe8jrvqpucsib6qm5o1f4j2ifsbb.apps.googleusercontent.com')     //provider: new GoogleLoginProvider('clientId')
    //       }
    //     ]
    //   } as SocialAuthServiceConfig,
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
