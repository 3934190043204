import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ExternalUserInterceptor } from './external-user/external-user.http-interceptor';
import { AzureADAuthInterceptor } from './azure-ad-auth/azure-ad-auth.http-interceptor';
import { MessageInterceptor } from './message/message.http-interceptor';
import { FIREBASE_DI_TOKEN, FIREBASE_UI_DI_TOKEN } from './external-user/external-user.service';
import { ACTIONS_LIST_DI_TOKEN, FAB_ACTION_DI_TOKEN } from './action';

declare const firebase: any;
declare const firebaseui: any;

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [

    { provide: HTTP_INTERCEPTORS, useClass: MessageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ExternalUserInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AzureADAuthInterceptor, multi: true },

    // useValue does not work here because the "firebase"
    // object has circular references and this trigger an
    // infinite loop inside Angular, that is why the seemingly
    // unnecessary factory is being used.
    // Another reason is that TypeScript does not support usage
    // of local (non-exported) references inside decorators.
    { provide: FIREBASE_DI_TOKEN, useFactory: () => firebase },
    { provide: FIREBASE_UI_DI_TOKEN, useFactory: () => firebaseui },

    { provide: ACTIONS_LIST_DI_TOKEN, useValue: [] },
    { provide: FAB_ACTION_DI_TOKEN, useValue: {} }

  ]
})
export class CoreModule { }
