import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { MessageService } from "../message/message.service";

// ADAL.js library
declare const AuthenticationContext: any;

const adalConfig = {
  instance: 'https://login.microsoftonline.com/',
  tenant: environment.azureAD.tenantId,
  clientId: environment.azureAD.applicationId,
  postLogoutRedirectUri: environment.azureAD.postLogoutRedirectUri
};

@Injectable({
  providedIn: 'root'
})
export class AzureADAuthService {

  private _authContext: any;

  constructor(private router: Router,
              private messageService: MessageService) {
  }

  acquireAuthToken(): Observable<string> {
    return new Observable(subscriber => {
      this.authContext.acquireToken(this.authContext.config.clientId, (_, token) => {
        // this happens when the token is expired
        if (token === undefined) {
          this.triggerLoginFlow();
        } else {
          subscriber.next(token);
          subscriber.complete();
        }
      });
    });
  }

  isAuthenticated(): boolean {
    return this.authContext.getCachedUser() !== null &&
      this.authContext.getCachedUser() !== undefined;
  }

  triggerLoginFlow() {
    this.authContext.login();
  }

  logout() {
    this.authContext.logOut();
  }

  handleLoginCallback() {
    var isCallback = this.authContext.isCallback(window.location.hash);
    this.authContext.handleWindowCallback();
    if (isCallback) {
      const loginErrorMessage = this.authContext.getLoginError();
      if (!loginErrorMessage) {
        this.router.navigate(['internal']);
      } else {
        this.messageService.error(loginErrorMessage).subscribe(() => {
          this.router.navigate([]);
        });
      }
    }
  }

  getCachedUser() {
    return this.authContext.getCachedUser();
  }

  private get authContext() {
    if (!this._authContext) {
      this._authContext = new AuthenticationContext(adalConfig);
    }
    return this._authContext;
  }

}
